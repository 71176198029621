import { getLanguage } from "../AppRedux/Actions";

export const format = function (_messages, message, values) {
    if (Object.hasOwnProperty.call(_messages, message)) {
        message = _messages[message];
    }
    if (values == null || !Array.isArray(values) || values.length <= 0) {
        return message;
    }
    let chunkIndex = 0;
    let placeholdersRegex = /\?/g;
    let result = '';
    let valuesIndex = 0;
    let match;
    while (valuesIndex < values.length && (match = placeholdersRegex.exec(message))) {
        result += message.slice(chunkIndex, match.index) + (values[valuesIndex] || '');
        chunkIndex = placeholdersRegex.lastIndex;
        valuesIndex++;
    }
    if (chunkIndex === 0) { return message; }
    if (chunkIndex < message.length) { return result + message.slice(chunkIndex); }
    return result;
}

const display = function () {
    let app_lang = (getLanguage() || 'en').toLowerCase();
    let { _messages } = require(`./${app_lang}.js`);
    let message = arguments[0] || ``;
    let values = [].slice.call(arguments, 1);
    return format(_messages, message, values);
}

export default display;