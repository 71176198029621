import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import Zoom from '@mui/material/Zoom';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import { Stack } from '@mui/system';
import SubmitButton from '../Buttons/CustomButton';


function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2, fontSize: 16 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon sx={{ height: "0.75em", width: "0.8em" }} />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

const Transition = React.forwardRef((props, ref) => {
    // const history = useHistory();
    return (
        <Zoom
            ref={ref}
            {...props}
        />
    );
});

export default function CustomModal(props) {
    const { open,
        onclose,
        onSubmit = () => { },
        title, size, children,
        footerCancelButton = false,
        cancelButton = false, submitButton = false,
        customButton = false, submitDisable = false,
        style = {}
    } = props
    const [fullWidth] = React.useState(true);
    const [maxWidth] = React.useState(size || false);
    return (
        <React.Fragment>
            <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={open}
                onClose={onclose}
                TransitionComponent={Transition}
                PaperProps={{
                    elevation: 0,
                    style: {
                        borderRadius: "0.5rem",
                        ...style
                        //boxShadow:"rgba(165, 161, 161, 0.59) 0px 0px 5px 2px, rgba(88, 85, 85, 0.1) 0px 4px 4px 5px"
                    }
                }}
                slotProps={{
                    backdrop: {
                        style: {
                            backdropFilter: "blur(4px)",
                            backgroundColor: "#000000cc"
                        }
                    }
                }}
                transitionDuration={{ appear: 450, enter: 450, exit: 600 }}
            >
                <BootstrapDialogTitle onClose={onclose}>{title}</BootstrapDialogTitle>
                <DialogContent>
                    {children}
                </DialogContent>
                {footerCancelButton &&
                    <DialogActions>
                        <SubmitButton variant="outlined" onClick={onclose}>Close</SubmitButton>
                    </DialogActions>
                }
                {(cancelButton || submitButton || customButton) && <div style={{ display: 'flex', alignItems: 'right', justifyContent: 'flex-end' }}>
                    <Stack className="head-title w-100" direction="row" spacing={2} justifyContent="flex-end" padding="0.5rem">
                        {customButton && <>
                            {customButton}
                        </>}
                        {cancelButton && <SubmitButton variant="outlined" onClick={onclose}>
                            {cancelButton}
                        </SubmitButton>}
                        {submitButton && <SubmitButton disabled={submitDisable} variant="contained" onClick={onSubmit}>
                            {submitButton}
                        </SubmitButton>}
                    </Stack>
                </div>}
            </Dialog>
        </React.Fragment>
    );
}