export const FETCH_START = "FETCH_START";
export const FETCH_SUCCESS = "FETCH_SUCCESS";
export const FETCH_SUCCESS_MSG = "FETCH_SUCCESS_MSG";
export const FETCH_ERROR = "FETCH_ERROR";
export const ERROR_BLANK = "ERROR_BLANK";
export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";
export const IS_USER_ONBOARD = "IS_USER_ONBOARD";
export const FETCH_SUCCESS_NO_LOADER = "FETCH_SUCCESS_NO_LOADER";
export const FETCH_SUCCESS_MSG_NO_LOADER = "FETCH_SUCCESS_MSG_NO_LOADER";
export const APP_MENUS = "APP_MENUS";
export const TAKE_NPS = "TAKE_NPS";
export const PERCENTAGE = "PERCENTAGE";
