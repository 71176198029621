/* eslint-disable */

import {
    INIT_URL,
    CHECKING_SCREEN_OFF,
    SIGNIN_USER_SUCCESS,
    SIGNOUT_USER_SUCCESS,
    AUTO_SIGNIN_USER_SUCCESS,
    APP_MENUS,
    MENU_SLUG_URL
} from "../ActionTypes/Auth";
import { get, post, setToken, setUser, clearStorage, getUser, getToken } from "./index";
export const setInitUrl = url => {
    return {
        type: INIT_URL,
        payload: url
    };
};

export const login = (userData, history) => {
    return (dispatch) => {
        post(dispatch, '', `user/login`, userData, {}, (data) => {
            setToken(data.token);
            setUser(data.auth);
            history.replace('/');
            dispatch({ type: SIGNIN_USER_SUCCESS, payload: { token: data.token, userData: data.auth } });
        });
    };
};

export const logout = (history) => {
    return (dispatch) => {
        get(dispatch, '', `user/logout`, {}, (data) => {
            clearStorage(false);
            history.replace('/login');
            dispatch({ type: SIGNOUT_USER_SUCCESS });
        });
    };
};

export const AutoLogin = (redirect_url, token, history) => {
    return (dispatch) => {
        get(dispatch, '', 'user', { token: token }, (auth) => {
            setUser(auth);
            setToken(token);
            dispatch({ type: AUTO_SIGNIN_USER_SUCCESS, payload: { token: token, userData: auth } });
            setTimeout(() => {
                dispatch({ type: CHECKING_SCREEN_OFF });
                history.replace(redirect_url);
            }, 10);
        }, () => {
            dispatch({ type: CHECKING_SCREEN_OFF });
            history.replace("/login");
        });
    };
};

export const SilentLogin = () => {
    return (dispatch) => {
        dispatch({ type: SIGNIN_USER_SUCCESS, payload: { token: getToken(), userData: getUser() } });
        dispatch({ type: CHECKING_SCREEN_OFF });
    };
};
function getMenuSlugs(data, storage) {
    for (let key in data) {
        const { menu_slug = null, url = "", children = [], menu_version = 2 } = data[key];
        if (menu_slug && url) storage[menu_slug] = url;
        if (children?.length > 0 && menu_version == 1) getMenuSlugs(children, storage);
    }
}

export const getMyMenus = () => {

    return async (dispatch) => {
        get(dispatch, '', 'user/myMenus', {}, (data) => {
            const { menu = [] } = data;
            const SLUG_WITH_URLS = {};
            Array.isArray(menu) && getMenuSlugs(menu, SLUG_WITH_URLS);
            dispatch({ type: MENU_SLUG_URL, payload: SLUG_WITH_URLS })
            dispatch({ type: APP_MENUS, payload: menu });
        });
    }
}