import { useEffect } from 'react';

function useTabFocus(callback) {
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        callback();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [callback]);
}

export default useTabFocus;
