import React, { useState } from "react";
import { FormHelperText, Grid, TextField, FormControlLabel, Checkbox, FormControl, FormLabel, RadioGroup, Radio, InputLabel, Select, Input, MenuItem, ListItemText, OutlinedInput, InputAdornment, IconButton, Switch, Box } from "@mui/material";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { date_format, add_date, add_month } from "../../utils";
import EditIcon from '@mui/icons-material/Edit';
import parse from "html-react-parser";
import FileBrowser from "./FileBrowser";
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { RxCross2 } from "react-icons/rx";
import InfoIcon from '@mui/icons-material/InfoOutlined';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import SwitchIcon from "../Switch/SwitchIcon";
import CustomSelect from "./CustomSelect";

import { components } from 'react-select';
import CustomAutoComplete from "./CustomAutoComplete";

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));

const FormBuilder = ({ fields, values, inputHandler, size, children, errors = {}, ...rest }) => {
    // let { fields, values, inputHandler, size, children, errors = {}, ...rest } = props;
    if (!size) {
        size = 'lg';
    }
    const [autoVal, setAutoVal] = useState({});
    const multiInputHandler = ({ target: { name, value: selectedValues } }, details) => {

        const SELECTED_ALL = 'all';
        const { options = [], key = 'id' } = details;
        const arraySet = new Set(selectedValues);

        let renderArr = Array.from(arraySet);

        if (arraySet.has(SELECTED_ALL)) {
            if (arraySet.size - 1 < options?.length) {
                renderArr = options.map(item => (typeof item === 'object' ? item?.[key] : item));

            }
            else renderArr = [];

        }

        inputHandler({ target: { name, value: renderArr } });
    };
    const handleCheckboxChange = (e) => {
        if (e.target.checked) {
            e.target.value = 1;
        } else {
            e.target.value = 0;
        }
        inputHandler(e);
    }
    const handleDateChange = (date, name, format = "Y-m-d") => {
        inputHandler({ target: { name: name, value: date_format(new Date(date), format) } });
    }

    const resetValues = (name, defaultValue) => {
        inputHandler({ target: { name: name, value: defaultValue } });
    }

    const validateInput = (e, type, old_val) => {
        let [real, ...fraction] = [];
        switch (type) {
            case 'A':
                e.target.value = e.target.value.replace(/[^A-Za-z]/g, '');
                break;
            case 'AS':
                e.target.value = e.target.value.replace(/[^A-Z a-z]/g, '');
                break;
            case 'AN':
                e.target.value = e.target.value.replace(/\W/g, '');
                break;
            case 'ASN':
                e.target.value = e.target.value.replace(/[^A-Z a-z0-9]/g, '');
                break;
            case 'ANS':
                e.target.value = e.target.value.replace(/[^A-Za-z 0-9_\-!@%&*?=+,.]/g, '');
                break;
            case 'N':
                e.target.value = e.target.value.replace(/\D/g, '');
                break;
            case 'F':
                [real, ...fraction] = e.target.value.replace(/[^0-9.]/g, '').split(".");
                e.target.value = fraction.length > 0 ? `${real}.${fraction.join('')}` : real ? Number(real) : real;
                break;
            case 'P':
                [real, ...fraction] = e.target.value.replace(/[^0-9.]/g, '').split(".");
                if (fraction.length > 0) {
                    let f = Number(fraction.join('')) || 0;
                    e.target.value = real > 100 || real < 0 || f > 99 || (f > 0 && real > 99) || fraction.join('').length > 2 ? old_val : `${Number(real)}.${fraction.join('')}`;
                } else {
                    e.target.value = real > 100 || real < 0 ? old_val : Number(real);
                }
                break;
            case 'MNO':
                e.target.value = e.target.value.replace(/\D/g, '').substring(0, 10);
                break;
            case 'PIN':
                e.target.value = e.target.value.replace(/\D/g, '').substring(0, 6);
                break;
            case 'EMAIL':
                e.target.value = e.target.value.replace(/[^A-Za-z0-9_\-@.]/g, '');
                break;
            default:
                break;
        }
        return e.target.value !== old_val;
    }

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const FormLabeler = (options) => {
        return <FormLabel component="legend">
            {options.label}
            {options.required ? <span aria-hidden="true" className="MuiFormLabel-asterisk MuiInputLabel-asterisk"> *</span> : ''}
        </FormLabel>
    }
    const InputLabeler = (options) => {
        return <InputLabel htmlFor={options.name}>
            {options.label}
            {options.required ? <span aria-hidden="true" className="MuiFormLabel-asterisk MuiInputLabel-asterisk"> *</span> : ''}
        </InputLabel>
    }

    if (!fields || !inputHandler || !values || typeof fields !== "object" || typeof inputHandler !== "function") {
        return (
            <ol>
                <li>
                    fields : required and should be type of Array object.
                </li>
                <li>
                    values : required and should be type of object.
                </li>
                <li>
                    inputHandler : required and should be type of function.
                </li>
            </ol>
        );
    } else {
        return (
            <Grid container alignItems="baseline" spacing={2} {...rest}>{
                Object.values(fields || []).map((options, i) => {
                    let materail_element = '';
                    let dt = new Date();
                    dt.setHours(0);
                    dt.setMinutes(0);
                    dt.setSeconds(0);
                    dt.setMilliseconds(0);
                    let size_chart = [12, 6, 4, 3];
                    if (typeof size === 'object') {
                        size_chart = size;
                    } else if (['xs', 'sm'].indexOf(size) !== -1) {
                        size_chart = [12, 12, 12, 12];
                    } else if (size === 'md') {
                        size_chart = [12, 12, 6, 6];
                    }
                    if (Object.hasOwnProperty.call(options, 'size_chart')) {
                        size_chart = options.size_chart;
                    }
                    switch (options.type) {
                        case 'html':
                            materail_element = parse(options.html)
                            break;
                        case 'input':
                            materail_element = (
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    placeholder={options.placeholder || ""}
                                    error={errors[options?.name]}
                                    helperText={errors[options?.name]?.message}
                                    fullWidth
                                    onChange={(e) => {
                                        if (validateInput(e, options.validate || 'ANY', values[options.name] === undefined || values[options.name] === null ? '' : values[options.name])) {
                                            inputHandler(e);
                                        }
                                    }}
                                    required={options.required || false}
                                    disabled={options.readonly || options.disabled || false}
                                    id={options.name || ('inp_id_' + i)}
                                    label={options.label}
                                    name={options.name}
                                    value={values[options.name] === undefined || values[options.name] === null ? '' : values[options.name]}
                                    InputProps={{
                                        style: { paddingRight: 0 },
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {(values[options.name] || values[options.name] == 0) && !(options.disabled || options.readonly) && (
                                                    <IconButton onClick={() => resetValues(options?.name, '')}>
                                                        <RxCross2 style={{ height: 18, width: 18, fontWeight: 700 }} />
                                                    </IconButton>
                                                )}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )
                            break;
                        case 'password':
                            materail_element = (
                                <FormControl fullWidth variant="outlined" size="small">
                                    {InputLabeler(options)}
                                    <OutlinedInput
                                        id={options.name || ('inp_id_' + i)}
                                        label={`${options.label} ${options.required ? '*' : ''} `}
                                        name={options.name}
                                        value={values[options.name] === undefined || values[options.name] === null ? '' : values[options.name]}
                                        type={autoVal[options.name] ? 'text' : options.type}
                                        onChange={(e) => {
                                            if (validateInput(e, options.validate || 'ANY', values[options.name] === undefined || values[options.name] === null ? '' : values[options.name])) {
                                                inputHandler(e);
                                            }
                                        }}
                                        inputProps={{
                                            autoComplete: "no"
                                        }}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => { setAutoVal({ ...autoVal, [options.name]: !autoVal[options.name] }) }}
                                                    onMouseDown={(event) => { event.preventDefault(); }}
                                                    edge="end"
                                                >
                                                    {autoVal[options.name] ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            )
                            break;
                        case 'hidden':
                            materail_element = (
                                <Input
                                    type={options.type}
                                    required={options.required || false}
                                    id={options.name || ('inp_id_' + i)}
                                    label={''}
                                    name={options.name}
                                    value={values[options.name] === undefined || values[options.name] === null ? '' : values[options.name]}
                                />
                            )
                            break;
                        case 'checkbox':
                            materail_element = (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name={options.name}
                                            checked={(values[options.name] === 1 || values[options.name] === "1" || (options.checkedVal !== undefined && values[options.name] === options.checkedVal)) ? true : false}
                                            disabled={options.readonly || options.disabled || false}
                                            onChange={handleCheckboxChange}
                                        />
                                    }
                                    labelPlacement="end"
                                    label={options.label}
                                />
                            )
                            break;
                        case 'groupselect':
                            materail_element = (<FormControl variant="outlined" size="small" fullWidth>
                                {InputLabeler(options)}
                                <Select native
                                    value={values[options.name] === undefined || values[options.name] === null ? '' : values[options.name]}
                                    onChange={inputHandler}
                                    required={options.required || false}
                                    disabled={options.readonly || options.disabled || false}
                                    label={`${options.label}${options.required ? ' * ' : ''}`}
                                    inputProps={{
                                        name: options.name,
                                        id: options.name
                                    }}
                                >
                                    <option aria-label="None" value="" />
                                    {React.Children.toArray(options?.options?.map((opg, i) => {
                                        return <optgroup label={opg.key}>
                                            {React.Children.toArray(opg?.value?.map((opt, i) => {
                                                if (typeof opt === "string") {
                                                    opt = { id: opt, name: opt };
                                                }
                                                return <option value={opt.id}>{opt.name}</option>
                                            }))}
                                        </optgroup>
                                    }))}
                                </Select>
                            </FormControl>)
                            break;
                        case 'select':
                            materail_element = (
                                <React.Fragment>
                                    <FormControl variant="outlined" size="small" fullWidth error={errors[options?.name]}>
                                        <CustomAutoComplete
                                            hideSelectedOptions={false}
                                            name={options.name}
                                            options={options.options}
                                            required={options.required}
                                            values={values[options.name]}
                                            inputHandler={inputHandler}
                                            valueKey={options.key}
                                            labelKey={options.value}

                                            additionalInfo={options}
                                        />
                                    </FormControl>
                                    {errors[options?.name]?.message && <FormHelperText sx={{ color: 'red', marginLeft: "2px" }}>{errors[options?.name]?.message}</FormHelperText>}
                                </React.Fragment>
                            )
                            break;
                        case 'multiselect':
                            materail_element = (<FormControl variant="outlined" size="small" fullWidth error={errors[options?.name]}>
                                <CustomAutoComplete
                                    isMulti={true}
                                    disabled={true}
                                    hideSelectedOptions={false}
                                    closeMenuOnSelect={false}
                                    name={options.name}
                                    required={options.required}
                                    options={options.options}
                                    values={values[options.name]}
                                    inputHandler={inputHandler}
                                    valueKey={options.key}
                                    labelKey={options.value}
                                    additionalInfo={options}

                                />

                                {errors[options?.name]?.message && <FormHelperText sx={{ color: 'red', marginLeft: "2px" }}>{errors[options?.name]?.message}</FormHelperText>}
                            </FormControl>)
                            break;
                        case 'radio':
                            materail_element = (
                                <FormControl component="fieldset">
                                    {FormLabeler(options)}
                                    <RadioGroup
                                        row
                                        aria-label={options.label}
                                        name={options.name}
                                        value={values[options.name] === undefined || values[options.name] === null ? '' : values[options.name]}
                                        onChange={inputHandler}
                                        required={options.required || false}
                                        disabled={options.readonly || options.disabled || false}
                                    >
                                        {React.Children.toArray(options?.options?.map((rad, i) => {
                                            if (typeof rad === "string") {
                                                rad = { id: rad, name: rad };
                                            }
                                            else if (typeof rad == "object") {
                                                rad = { id: rad.id, name: rad.name }
                                            }
                                            return <FormControlLabel value={rad.id} control={<Radio size="small" />} label={rad.name} />
                                        }))}
                                    </RadioGroup>
                                </FormControl>
                            )
                            break;
                        case 'date':
                            materail_element = (
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        clearable
                                        inputVariant="outlined"
                                        format="dd-MMM-yyyy"
                                        // views={['year', 'month', 'day']}
                                        // openTo="year"
                                        size="small"
                                        fullWidth
                                        allowSameDateSelection
                                        label={options.label}
                                        value={values[options.name] ? new Date(values[options.name]) : null}
                                        onChange={(date) => { handleDateChange(date, options.name, "Y-m-d") }}
                                        renderInput={(params) => <TextField
                                            size="small"
                                            required={options.required || false}
                                            fullWidth
                                            {...params}
                                            error={errors[options?.name]}
                                            helperText={errors[options?.name]?.message}
                                        />}
                                        disabled={options.readonly || options.disabled || false}
                                        minDate={new Date(add_date(dt, (-1) * (options.min === undefined ? 60 : Number(options.min))))}
                                        maxDate={new Date(add_date(dt, options.max === undefined ? 60 : Number(options.max)))}
                                        inputFormat={'DD-MMM-YYYY'}

                                    />
                                </LocalizationProvider>
                            );
                            break;
                        case 'month':
                            materail_element = (
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        clearable
                                        inputVariant="outlined"
                                        views={['year', 'month']}
                                        format="MMM-yyyy"
                                        // openTo="year"
                                        size="small"
                                        fullWidth
                                        label={`${options.label}${options.required ? ' * ' : ''}`}
                                        value={values[options.name] ? new Date(values[options.name]) : null}
                                        onChange={(date) => { handleDateChange(date, options.name, "Y-m-01") }}
                                        renderInput={(params) => <TextField {...params} size='small' fullWidth error={errors[options?.name]} helperText={errors[options?.name]?.message} />}
                                        required={options.required || false}
                                        disabled={options.readonly || options.disabled || false}
                                        minDate={new Date(add_month(dt, (-1) * (options.min === undefined ? 60 : Number(options.min)), 'Y-m-01 00:00:00'))}
                                        maxDate={new Date(add_month(dt, options.max === undefined ? 60 : Number(options.max), 'Y-m-t 23:23:59'))}
                                        inputFormat={'MMM-YYYY'}
                                    />
                                </LocalizationProvider>
                            );
                            break;
                        case 'year':
                            materail_element = (
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        clearable
                                        inputVariant="outlined"
                                        views={['year']}
                                        format="yyyy"
                                        openTo="year"
                                        size="small"
                                        fullWidth
                                        label={`${options.label}${options.required ? ' * ' : ''}`}
                                        value={values[options.name] ? new Date(values[options.name]) : null}
                                        onChange={(date) => { handleDateChange(date, options.name, "Y-01-01") }}
                                        renderInput={(params) => <TextField {...params} />}
                                        required={options.required || false}
                                        disabled={options.readonly || options.disabled || false}
                                        minDate={new Date(add_month(dt, (-12) * (options.min === undefined ? 60 : Number(options.min))))}
                                        maxDate={new Date(add_month(dt, (12) * (options.max === undefined ? 60 : Number(options.max))))}
                                        inputFormat={'YYYY'}
                                    />
                                </LocalizationProvider >);
                            break;
                        case 'textarea':
                            materail_element = (
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    onChange={(e) => {
                                        if (validateInput(e, options.validate || 'ANY', values[options.name] === undefined || values[options.name] === null ? '' : values[options.name])) {
                                            inputHandler(e);
                                        }
                                    }}
                                    required={options.required || false}
                                    disabled={options.readonly || options.disabled || false}
                                    id={options.name || ('inp_id_' + i)}
                                    label={options.label}
                                    name={options.name}
                                    value={values[options.name] === undefined || values[options.name] === null ? '' : values[options.name]}
                                    multiline
                                    error={errors[options?.name]}
                                    helperText={errors[options?.name]?.message}
                                    rowsMax={3}
                                    minRows={options.minRows || 1}
                                />
                            )
                            break;
                        case 'switch':
                            materail_element = (
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <FormControlLabel
                                        control={
                                            <Switch color="primary"
                                                name={options.name}
                                                checked={(values[options.name] === 1 || values[options.name] === "1") ? true : false}
                                                onChange={handleCheckboxChange}
                                                disabled={options.readonly || options.disabled || false}
                                            />
                                        }
                                        labelPlacement="end"
                                        label={<span style={{ textDecorationLine: options.displayLabel && options.label !== options.displayLabel ? 'line-through' : 'none', textDecorationStyle: 'solid' }}>{options.label}</span>}
                                    />
                                    {options.displayLabel && options.label !== options.displayLabel && <span>{options.displayLabel}</span>}
                                    {options.editOption && <EditIcon sx={{ m: 1, cursor: "pointer" }} onClick={() => { options.editOption(options) }} fontSize='small' />}
                                </div>
                            )
                            break;
                        case 'switch2':
                            materail_element = (
                                <div style={{ display: "flex", alignItems: "center" }} className='switch2'>
                                    <FormControlLabel
                                        control={
                                            <SwitchIcon
                                                name={options.name}
                                                checked={(values[options.name] === 1 || values[options.name] === "1") ? true : false}
                                                onChange={handleCheckboxChange}
                                            />
                                        }

                                        labelPlacement="end"
                                        label={<span style={{ fontSize: 16, fontWeight: 400 }}>{options.displayLabel}</span>}
                                    />
                                    {options.displayLabel && options.label !== options.displayLabel ? <LightTooltip title={options.label} placement="right-start" arrow><Box className="icon-head-info" sx={{ padding: '5px' }}>
                                        <InfoIcon sx={{ fontSize: 16, color: '#30323E' }} />
                                    </Box></LightTooltip> : ''}
                                    {options.editOption && <Box className="icon-rounded-white" onClick={() => { options.editOption(options) }} sx={{ padding: '5px', marginLeft: '5px' }}>
                                        <ModeEditOutlineOutlinedIcon sx={{ fontSize: 16, color: '#30323E' }} />
                                    </Box>}
                                </div>
                            )
                            break;
                        case 'autocomplete':
                            materail_element = (
                                <div className="w-100" style={{ display: "inline-flex", verticalAlign: "top", flexDirection: "column" }}>
                                    <CustomSelect
                                        {...options?.selectOptions ?? {}}
                                        name={options.name}
                                        options={options.options}
                                        values={values[options.name]}
                                        inputHandler={inputHandler}
                                        valueKey={options.key}
                                        labelKey={options.value}
                                    />
                                </div>
                            );
                            break;
                        case 'file':
                            materail_element = (
                                <FileBrowser
                                    options={options}
                                    inputHandler={inputHandler}
                                    value={values[options.name]}
                                />
                            );
                            break;
                        default:
                            break;
                    }
                    return React.Children.toArray(options.type === 'hidden' ? materail_element : (
                        options.format ? <Grid container item  {...(options.size ? { xs: options.size[0], sm: options.size[1], md: options.size[2], lg: options.size[3] } : { xs: size_chart[0], sm: size_chart[1], md: size_chart[2], lg: size_chart[3] })}>{options.format(materail_element)}</Grid> :
                            <Grid
                                item
                                {...(options.size ? { xs: options.size[0], sm: options.size[1], md: options.size[2], lg: options.size[3] } : { xs: size_chart[0], sm: size_chart[1], md: size_chart[2], lg: size_chart[3] })}
                            >
                                {materail_element}
                            </Grid>
                    ));
                })
            }{children}</Grid >
        );
    }
};
export default FormBuilder;