import React from 'react';
import { Switch, Case, Default, When } from 'react-if';
import {
    BREAKDOWN_ARREAR_MODAL,
    BULK_RECOMPUTE_ALERT,
    COMMON_ALERT,
    COMPUTE_EMPLOYEES_PAYROLL,
    DELETE_EMPLOYEES_ATTENDANCE,
    DISBURSHMENTSUMMARY,
    EDIT_EMPLOYEE_DRAWER,
    EDIT_PAYROLL_DRAWER,
    EMPLOYEE_PAYROLL_DRAWER,
    HEAD_CONFIG,
    INT_EMPLOYEE_PAYROLL_DRAWER,
    PAYROLL_RECOMPUTE_ALERT,
    RULES_CONFIG,
    TIPS_ATTENDANCE_UPDATE
} from '../../constants';
import { usePayrollHook } from '../../hooks/usePayrollHook';
import CreateHead from '../../pages/Bonus/CreateHead';
import EditEmployee from '../../pages/HomePage/EditEmployee';
import PayoutSummary from '../../pages/Payout/PayoutSummary';
import ArrearBreakDown from '../../pages/Process_Arrear/ArrearBreakDown';
import CustomAlert from '../CustomAlert/CustomAlert';
import CustomRightDrawer from '../CustomDrawer/CustomRightDrawer';
import CustomModal from '../CustomModal/CustomModal';
import EmployeePayroll from '../../pages/review_run_payroll/EmployeePayroll';
import { EmployeePayroll as IntEmployeePayroll } from "../../International/review_run_payroll/EmployeePayroll"
import EditEmployeePayroll from '../../pages/review_run_payroll/EditEmployeePayroll';

import CachedIcon from '@mui/icons-material/Cached';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { displayLoader, fetchDataByThread, get, hideLoader, post } from '../../AppRedux/Actions';
import { useDispatch } from 'react-redux';
import CreateRules from '../../pages/Bonus/CreateRules';

const ModalGroup = ({ children }) => {
    const dispatch = useDispatch();
    const {
        drawerRightOpen,
        Drawertype,
        modalType,
        setDrawerRightOpen,
        setOpenModal,
        openModal,
        payrollDetail,
        editDetails,
        checkedIds,
        runPayrollIds,
        SetFinalPayrolls,
        finalPayrolls,
        SetFinalPayrollsError,
        FinalPayrollsError,
        setRunPayrollIds,
        setCheckedIds,
        employeeList,
        setEmployeeList,
        filterResult, FETCH_EMPLOYEES_ATTENDANCE_APICALL } = usePayrollHook();


    let threadChecker = null;
    const DataReCompute = () => {

        if (Object.keys(checkedIds)?.length <= 0) return 0;
        const payload = {
            emp: Object.keys(checkedIds),
            ...filterResult
        }

        displayLoader(dispatch);
        post(dispatch, '', DELETE_EMPLOYEES_ATTENDANCE, payload, {}, (data) => {
            setCheckedIds({});
            const { emp_attd = {}, errors = [], pending_count = 0, thread_id = null } = data;
            setEmployeeList({ ...employeeList, ...emp_attd });
            setOpenModal(false);
            setCheckedIds({ ...emp_attd });
            let inital_total = (Object.keys(emp_attd)?.length ?? 0) + (errors?.length ?? 0);
            dispatch({ type: 'PERCENTAGE', payload: { percentage: inital_total / (inital_total + pending_count) * 100 } });
            fetchDataByThread(dispatch, pending_count, thread_id, inital_total, ({ response = {} }) => {
                setEmployeeList({ ...employeeList, ...response });
                setCheckedIds({ ...checkedIds, ...response });
            });
        }, () => hideLoader(dispatch), true);
    }

    // const DataReCompute = () => {
    //     if (Object.keys(checkedIds)?.length <= 0) return 0;
    //     const payload = {
    //         emp: Object.keys(checkedIds),
    //         ...filterResult
    //     }

    //     post(dispatch, '', DELETE_EMPLOYEES_ATTENDANCE, payload, {}, (res) => {
    //         const { emp_attd = {}} = res;
    //         setCheckedIds({});
    //         setEmployeeList((prevEmpList) => ({ ...prevEmpList, ...emp_attd }));
    //         setOpenModal(false);
    //     });
    // }

    // const ReComputePayroll = () => {
    //     if (Object.keys(runPayrollIds)?.length <= 0) return 0;
    //     const payload = {
    //         emp_id: Object.keys(runPayrollIds),
    //         recompute: true,
    //         org_config: OrgConfig
    //     }

    //     post(dispatch, '', COMPUTE_EMPLOYEES_PAYROLL, payload, {}, () => {
    //         setOpenModal(false);
    //         setRunPayrollIds({});
    //         FetchEmployeesPayroll();
    //     });
    // }

    let checker = null;
    const ReComputePayroll = () => {
        if (Object.keys(runPayrollIds)?.length <= 0) return 0;
        let employees_payroll_to_be_recomputed = Object.keys(runPayrollIds).map(eid => {
            return checkedIds[eid];
        })
        const payload = {
            emp_details: [...employees_payroll_to_be_recomputed],
            filters: filterResult,
            recompute: true
        }

        displayLoader(dispatch);
        post(dispatch, '', COMPUTE_EMPLOYEES_PAYROLL, payload, {}, (data) => {
            const { payrolls = {}, errors = [], pending_count = 0, thread_id = null } = data;

            SetFinalPayrolls({ ...finalPayrolls, ...payrolls });
            SetFinalPayrollsError([...FinalPayrollsError, ...errors]);
            setRunPayrollIds({});
            setOpenModal(false);

            let inital_total = (Object.keys(payrolls)?.length ?? 0) + (errors?.length ?? 0);
            dispatch({ type: 'PERCENTAGE', payload: { percentage: inital_total / (inital_total + pending_count) * 100 } });
            fetchDataByThread(dispatch, pending_count, thread_id, inital_total, ({ response = {} }) => {
                SetFinalPayrolls({ ...payrolls, ...response });
            });
        }, () => hideLoader(dispatch), true);
    }

    /*modal functions */
    // function FetchEmployeesPayroll() {
    //     post(dispatch, '', COMPUTE_EMPLOYEES_PAYROLL, { org_config: OrgConfig, emp_id: Object.keys(checkedIds) }, {}, (data) => {
    //         if (data?.payrolls && data?.payrolls?.length > 0) {
    //             SetFinalPayrolls([...data.payrolls]);
    //             setActiveStep(2);
    //         }
    //     });
    // }
    return (
        <>
            {children}
            <CustomRightDrawer open={drawerRightOpen} handleCloseRightDrawer={() => { setDrawerRightOpen(false) }}>
                <Switch>
                    <Case condition={Drawertype === EDIT_EMPLOYEE_DRAWER}>
                        <EditEmployee
                            editEmployeeDetail={editDetails}
                            handleCloseRightDrawer={() => { setDrawerRightOpen(false) }}
                            fetchEmployee={FETCH_EMPLOYEES_ATTENDANCE_APICALL}
                        />
                    </Case>
                    <Case condition={Drawertype === EMPLOYEE_PAYROLL_DRAWER}>
                        <EmployeePayroll
                            payrollDetail={payrollDetail}
                            handleCloseRightDrawer={() => { setDrawerRightOpen(false) }}
                        />
                    </Case>
                    <Case condition={Drawertype === INT_EMPLOYEE_PAYROLL_DRAWER}>
                        <IntEmployeePayroll
                            payrollDetail={payrollDetail}
                            handleCloseRightDrawer={() => { setDrawerRightOpen(false) }}
                        />
                    </Case>
                    <Case condition={Drawertype === EDIT_PAYROLL_DRAWER}>
                        <EditEmployeePayroll
                            payrollDetail={payrollDetail}
                            handleCloseRightDrawer={() => { setDrawerRightOpen(false) }}
                        />
                    </Case>
                    <Default>No Type Select</Default>
                </Switch>
            </CustomRightDrawer>
            <When condition={openModal}>
                <CustomModal title={modalType?.title} open={openModal} onclose={() => { setOpenModal(false) }} size={modalType?.size || "xs"}>
                    <Switch>
                        <Case condition={modalType.componentName === BULK_RECOMPUTE_ALERT}>
                            <CustomAlert
                                labelCancel="Cancel"
                                labelProceed="Proceed"
                                onCancel={() => { setOpenModal(false) }}
                                onProceed={DataReCompute}
                                // AlertIcon={<CachedIcon />}
                                title="Bulk Recompute Attendance"
                                subtitle="This will undo the payroll of selected employees"
                            />
                        </Case>
                        <Case condition={modalType.componentName === PAYROLL_RECOMPUTE_ALERT}>
                            <CustomAlert
                                labelCancel="Cancel"
                                labelProceed="Proceed"
                                onCancel={() => { setOpenModal(false) }}
                                onProceed={ReComputePayroll}
                                AlertIcon={<ReceiptIcon />}
                                title="Bulk Recompute Payroll"
                                subtitle="This will undo the payroll of selected employees"
                            />
                        </Case>
                        <Case condition={modalType.componentName === HEAD_CONFIG}>
                            <CreateHead />
                        </Case>
                        <Case condition={modalType.componentName === RULES_CONFIG}>
                            <CreateRules />
                        </Case>
                        <Case condition={modalType.componentName === BREAKDOWN_ARREAR_MODAL}>
                            <ArrearBreakDown breakdown={modalType?.data || []} />
                        </Case>
                        <Case condition={modalType.componentName === DISBURSHMENTSUMMARY}>
                            <PayoutSummary />
                        </Case>
                        <Case condition={modalType.componentName === COMMON_ALERT}>
                            <CustomAlert
                                {...modalType?.data}
                            />
                        </Case>
                        <Default>No Type Select</Default>
                    </Switch>
                </CustomModal>
            </When>
        </>
    )
}

export default ModalGroup