import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from "yup";
import {
    Grid,
    InputLabel,
    TextField,
    FormControl,
    MenuItem,
    Button
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { usePayrollHook } from '../../hooks/usePayrollHook';
import { post, get } from '../../AppRedux/Actions';
import { useDispatch } from 'react-redux';
import { ADD_UPDATE_BONUS_HEADS, ORG_BONUS_HEADS } from '../../constants';
import ValidateFields from '../../components/FormBuilder/ValidateFields';
import SubmitButton from '../../components/Buttons/CustomButton';

const CustomSelect = styled(TextField)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(2)
    },
    '& .MuiInputBase-select': {
        borderRadius: 4,
        position: 'relative',
        border: '1px solid #ced4da',
        fontSize: 16,
        width: "100%",
        padding: '5px 6px',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        '&:focus': {
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
        },
    },
}));

const CustomInputLabel = styled(InputLabel)({
    fontSize: 16,
    fontWeight: 600,
    color: '#30323E'
});

const CreateHead = () => {
    const dispatch = useDispatch();

    const { setOpenModal, setHeads, editHeads, setEditHeads } = usePayrollHook();
    const headsDetails = Object.keys(editHeads).length ? editHeads : { HEAD_NAME: "", STATUS: "0" }
    function handleChange(e) {
        const { name, value } = e.target;
        setValue(name, value)
    }
    function FetchBonusHead() {
        get(dispatch, '', ORG_BONUS_HEADS, {}, (data) => {
            setHeads([...data || []]);
        });
    }

    function handleSaveHeads() {
        post(dispatch, '', ADD_UPDATE_BONUS_HEADS, getValues(), {}, (data) => {
            FetchBonusHead();
            setEditHeads({});
            setOpenModal(false);
        })
    }
    const configurationValidation = Yup.object({
        HEAD_NAME: Yup.string().required("Required"),
    });
    //validations
    const { register, getValues, handleSubmit, formState: { errors }, setValue } = useForm({
        defaultValues: headsDetails,
        resolver: yupResolver(configurationValidation)
    });

    return (
        <Grid container direction="column" xs={12}>
            <Grid item xs={12}>
                <FormControl variant="standard" sx={{ width: "100%" }}>
                    <CustomInputLabel shrink htmlFor="name-input">
                        Head Name
                    </CustomInputLabel>
                    <ValidateFields
                        errors={errors}
                        defaultValue={getValues("HEAD_NAME")}
                        placeholder="Enter Head Name"
                        name="HEAD_NAME"
                        onChange={handleChange}
                        sx={{
                            'label + &': {
                                marginTop: 2
                            }
                        }}
                        inputRef={register("HEAD_NAME")}
                    />

                </FormControl>
            </Grid>
            <Grid xs={12} sx={{ marginTop: 2 }}>
                <FormControl variant="standard" sx={{ width: "100%" }}>
                    <CustomInputLabel shrink htmlFor="name-input">
                        Status
                    </CustomInputLabel>
                    <CustomSelect
                        size="small"
                        defaultValue={getValues("STATUS")}
                        select
                        onChange={handleChange}
                        name="STATUS"
                        inputRef={register("STATUS")}
                    >
                        <MenuItem value="1">Active</MenuItem>
                        <MenuItem value="0">InActive</MenuItem>
                    </CustomSelect>
                </FormControl>
            </Grid>

            <Grid container xs={12} sx={{ marginTop: 2 }} justifyContent="end" columnGap={2}>
                <Grid>
                    <SubmitButton variant="outlined" onClick={() => setOpenModal(false)}>Cancel</SubmitButton>
                </Grid>
                <Grid>
                    <SubmitButton variant="contained" onClick={handleSubmit(handleSaveHeads)}>Save</SubmitButton>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default CreateHead