import { getUser } from "../../AppRedux/Actions";
import { INDIAN_COUNTRY_CODE } from "../../utils";


const ACTIVE_COUNTRY = getUser('active_country_id');

export const columnlabel = {
    EMPLOYEE_ID: 'Employee',
    emp_name: 'Employee Name',
    department_name: 'Department',
    emp_pay_day: 'Paid Days',
    computed_gross: 'Gross Pay',
    DEDUCTION: 'Deductions',
    taxes: 'Taxes',
    arrear: 'Arrear',
    NET_PAYABLE: 'Net Pay',
    ACTION: "Action"
};

export const NUMBER_FORMAT = ACTIVE_COUNTRY == INDIAN_COUNTRY_CODE ? "N":"F";