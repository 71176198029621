import axios from "axios";
import swal from 'sweetalert';

import {
    FETCH_START,
    FETCH_SUCCESS,
    FETCH_SUCCESS_MSG,
    FETCH_ERROR,
    SHOW_LOADER,
    HIDE_LOADER,
    IS_USER_ONBOARD,
    FETCH_SUCCESS_MSG_NO_LOADER,
    FETCH_SUCCESS_NO_LOADER,
    TAKE_NPS
} from "../ActionTypes";

import display from "../../lang";
import { CHECK_THREAD_STATUS, threadTime } from "../../constants";
import { showToast } from "../constant";

export const setToken = (token) => {
    return localStorage.setItem(`token`, token);
}

export const getToken = () => {
    return localStorage.getItem(`token`) || false;
}

export const setUser = (user) => {
    return localStorage.setItem(`user`, JSON.stringify(user));
}

export const getUser = (name) => {
    const auth = JSON.parse(localStorage.getItem(`user`) || '{}');
    if (name && Object.hasOwnProperty.call(auth, name)) {
        return auth[name];
    } else {
        return auth;
    }
}

export const getHeaders = (customHeader = {}) => {
    let headers = {
        "app_name": `${process.env.REACT_APP_NAME}`,
        "app_lang": getLanguage() || "en",
        "Content-Type": "application/json"
    }
    let token = getToken();
    if (token) {
        headers['token'] = token;
    }
    for (const name in customHeader) {
        if (customHeader[name]) {
            headers[name] = customHeader[name];
        }
    }
    return headers;
};

export const get = (dispatch, baseUrl, url, headers, success, error = () => { }, manual_loader = false, show_loader = true) => {
    show_loader && dispatch({ type: FETCH_START });
    axios.get(`${baseUrl || window.env.REACT_APP_API_BASE_URL}/${url}`, {
        headers: getHeaders(headers || {}),
    }).then(({ data }) => {
        if (!data.error) {
            if (data.message && data.message.length > 0) {
                showToast(data.message, 'success');
                dispatch({ type: manual_loader ? FETCH_SUCCESS_MSG_NO_LOADER : FETCH_SUCCESS_MSG, payload: data.message });
            } else {
                dispatch({ type: manual_loader ? FETCH_SUCCESS_NO_LOADER : FETCH_SUCCESS });
            }
            // if (data.message && data.message.length > 0) {
            //     dispatch({ type: FETCH_SUCCESS_MSG, payload: data.message })
            // } else {
            //     dispatch({ type: FETCH_SUCCESS });
            // }
            success(data.data);
        } else {
            dispatchError(data, dispatch, () => {
                error();
            });
        }
    }).catch((e) => {
        checkError(e, dispatch, () => {
            error();
        });
    });
};
export const downloadFile = (dispatch, url, payload, headers = {}, success = () => { }, error = () => { }) => {
    dispatch({ type: FETCH_START });
    axios.post(`${window.env.REACT_APP_API_BASE_URL}/${url}`, payload, {
        headers: getHeaders(headers || {}),
    }).then(({ data }) => {
        if (!data.error) {
            if (data.message && data.message.length > 0) {
                dispatch({ type: FETCH_SUCCESS_MSG, payload: data.message });
            }
            let { buffer64, file, contentType } = data.data;
            let a = document.createElement("a");
            a.href = `data:${contentType};base64, ${buffer64}`;
            a.setAttribute("download", file);
            a.click();
            dispatch({ type: FETCH_SUCCESS });
            success();
        } else {
            dispatchError(data, dispatch, () => {
                error();
            });
        }
    }).catch((e) => {
        checkError(e, dispatch, () => {
            error();
        });
    });
};

export const downloadFileuRL = (dispatch, url) => {
    dispatch({ type: FETCH_START });
    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = url.substring(url.lastIndexOf('/') + 1);
    downloadLink.click();
    dispatch({ type: FETCH_SUCCESS });
}


// export function downloadFileBlob(dispatch, url) {
//     dispatch({ type: FETCH_START });
//     fetch(url)
//         .then(response => response.blob())
//         .then(blob => {
//             const objectURL = URL.createObjectURL(blob);
//             const downloadLink = document.createElement('a');
//             downloadLink.href = objectURL;
//             downloadLink.download = url.substring(url.lastIndexOf('/') + 1);
//             downloadLink.click();
//             URL.revokeObjectURL(objectURL);
//             dispatch({ type: FETCH_SUCCESS });
//         })
//         .catch(e => {
//             dispatch({ type: FETCH_ERROR, payload: e.message });
//         });
// }

export function downloadFileBlob(dispatch, url, filename = null) {
    dispatch({ type: FETCH_START });
    let req_status = 200;
    fetch(url, filename ? { headers: { token: getToken() } } : null)
        .then(response => {
            if (response.status == 200) {
                return response.blob();
            } else {
                req_status = response.status;
                return response.json();
            }
        })
        .then(blob => {
            if (req_status != 200) {
                dispatch({ type: FETCH_ERROR, payload: blob.message || "Server Error" });
            } else {
                const objectURL = URL.createObjectURL(blob);
                const downloadLink = document.createElement('a');
                downloadLink.href = objectURL;
                downloadLink.setAttribute("download", filename || url.substring(url.lastIndexOf('/') + 1));
                downloadLink.download = filename || url.substring(url.lastIndexOf('/') + 1);
                downloadLink.click();
                URL.revokeObjectURL(objectURL);
                dispatch({ type: FETCH_SUCCESS });
            }
        })
        .catch(e => {
            dispatch({ type: FETCH_ERROR, payload: e.message });
        });
}

export const downloadCSVError = (dispatch, data, header, title) => {
    if (!data || data == undefined || typeof data != 'object') {
        data = [{ error: "Empty file." }];
        header = { error: "Error" };
    }
    if (!header || header == undefined || typeof header != 'object') {
        header = {};
        for (const k in data[0]) {
            header[k] = k;
        }
    }
    let csv_str = ``;
    if (title != undefined) {
        if (typeof title != 'object' && title.length > 0) {
            csv_str += `${_csv_safe(title)}\r\n`;
        } else if (typeof title == 'object') {
            for (const k in title) {
                if (isNaN(k)) {
                    csv_str += `${_csv_safe(k)}\r\n`;
                }
                if (typeof title[k] == 'string') {
                    csv_str += `${_csv_safe(title[k])}\r\n`;
                }
            }
        }
    }
    for (const k in header) {
        if (typeof header[k] == 'string') {
            csv_str += `${_csv_safe(header[k])},`;
        } else {
            delete header[k];
        }
    }
    let hasData = false;
    for (const r of data) {
        if (r.error) {
            hasData = true;
            csv_str += `\r\n`;
            for (const k in header) {
                // if (typeof r[k] == 'string') {
                if (r[k] || r[k] == 0) {
                    csv_str += `${_csv_safe(r[k])},`;
                } else {
                    csv_str += `,`;
                }
            }
        }
    }
    let payload = {
        buffer64: window.btoa(unescape(encodeURIComponent(csv_str))),
        file: `error_${title}.csv`,
        contentType: "application/csv"
    }

    if (hasData) return downloadFileFromBuffer(dispatch, payload)
    return;
}

function _csv_safe(str) {
    // return `"${str.replace(/["]/g, '""')}"`;
    return typeof str == 'string' ? `"${str.replace(/["]/g, '""')}"` : str;
}

export function fileUploadOnAWS(dispatch, file, name, custom_path, success = (args) => { }) {
    dispatch({ type: FETCH_START });
    try {
        if (file[0]) {
            const data = new FormData();
            data.append('field_name', name);
            data.append('file_data', file[0]);
            if (custom_path) {
                data.append('filepath', custom_path);
            }
            post(dispatch, '', `upload_s3`, data, { "Content-Type": "multipart/form-data" }, resp => {
                success({ name: name, value: resp.file_name });
                dispatch({ type: FETCH_SUCCESS });
            });
        } else {
            success({ name: name, value: "" });
            dispatch({ type: FETCH_SUCCESS });
        }
    }
    catch (e) {
        dispatch({ type: FETCH_ERROR, payload: e.message });
    }
}


export const downloadFileFromBuffer = (dispatch, payload) => {
    dispatch({ type: FETCH_START });
    let { buffer64, file, contentType } = payload;
    if (buffer64 && contentType) {
        let a = document.createElement("a");
        a.href = `data:${contentType};base64, ${buffer64}`;
        a.setAttribute("download", file);
        a.click();
        dispatch({ type: FETCH_SUCCESS });
    }
    else {
        dispatchError({ message: display('FILE_NOT_AVALIABLE') }, dispatch, () => { });
    }
};

export const post = (dispatch, baseUrl, url, payload, headers, success, error = () => { }, manual_loader = false) => {
    if(!manual_loader) dispatch({ type: FETCH_START });
    axios.post(`${baseUrl || window.env.REACT_APP_API_BASE_URL}/${url}`, payload, {
        headers: getHeaders(headers || {}),
    }).then(({ data }) => {
        if (!data.error) {
            if (data.message && data.message.length > 0) {
                showToast(data.message, 'success');
                dispatch({ type: manual_loader ? FETCH_SUCCESS_MSG_NO_LOADER : FETCH_SUCCESS_MSG, payload: data.message });
            } else {
                dispatch({ type: manual_loader ? FETCH_SUCCESS_NO_LOADER : FETCH_SUCCESS });
            }
            success(data.data);
        } else {
            dispatchError(data, dispatch, () => {
                error();
            });
        }
    }).catch((e) => {
        checkError(e, dispatch, () => {
            error();
        });
    });
};

export function updateOnBoardStatus(dispatch, data) {
    dispatch({ type: IS_USER_ONBOARD, payload: data });
}
export function updateTakeNpstatus(dispatch, data) {
    dispatch({ type: TAKE_NPS, payload: data });
}

export const downloadXls = (dispatch, url, payload, headers = {}, success, error = () => { }) => {
    dispatch({ type: FETCH_START });
    axios.post(`${window.env.REACT_APP_API_BASE_URL}/${url}`, payload, {
        headers: getHeaders(headers || {}),
    }).then(({ data }) => {
        if (!data.error) {
            let a = document.createElement("a");
            a.href = `data:application/csv;base64, ${data.data}`;
            a.setAttribute("download", `file_${(new Date()) / 1}.csv`);
            a.click();
            if (data.message && data.message.length > 0) {
                dispatch({ type: FETCH_SUCCESS_MSG, payload: data.message });
            } else {
                dispatch({ type: FETCH_SUCCESS });
            }
            success();
        } else {
            dispatchError(data, dispatch, () => {
                error();
            });
        }
    }).catch((e) => {
        checkError(e, dispatch, () => {
            error();
        });
    });
};

export const checkError = (e, dispatch, next) => {
    if (e.response?.status === 404 || e.response?.status === "404") {
        dispatch({ type: FETCH_ERROR, payload: display('API_NOT_FOUND') });
        next();
    } else {
        if (e.response?.data) {
            dispatchError(e.response.data, dispatch, () => {
                next();
            });
        } else if (e.message === 'Network Error') {
            dispatch({ type: FETCH_ERROR, payload: display('NETWORK_ERROR') });
            next();
        } else {
            dispatch({ type: FETCH_ERROR, payload: e.message });
            next();
        }
    }
};

export const dispatchError = (data, dispatch, next) => {
    if (data?.forceLogin) {
        clearStorage();
        dispatch({ type: FETCH_ERROR, payload: data.message });
    } else {
        dispatch({ type: FETCH_ERROR, payload: data.message });
        // showToast(data.message, 'error');
        next();
    }

    showToast(data.message, 'error');

}

export const clearStorage = (reload = true) => {
    reload && reset_url();
    return localStorage.clear();
}

export const displayLoader = (dispatch) => {
    dispatch({ type: SHOW_LOADER });
}
export const hideLoader = (dispatch) => {
    dispatch({ type: HIDE_LOADER });
}

export const reset_url = () => {
    setTimeout(() => {
        window.top.location.href = window.top.location.origin;
    }, 1000);
}

export const setLanguage = (lang) => {
    const d = new Date();
    d.setTime(d.getTime() + (365 * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = "app_language=" + lang + ";" + expires + ";path=/";
    window.top.location.reload();
}

export const getLanguage = () => {
    let name = "app_language=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export const showError = (dispatch, message) => {
    dispatch({ type: FETCH_ERROR, payload: message });
}

export function fetchDataByThread(dispatch, pending_count, thread_id, inital_total, cb, error_cb = () => { }) {
    if (pending_count && thread_id) {
        setTimeout(() => {
            let URL = CHECK_THREAD_STATUS + `/run_payroll/${thread_id}`;
            get(dispatch, '', URL, {}, (res) => {
                const { status = 0, processed_count = 0, } = res;
                let completedCount = inital_total + processed_count;
                let get_percentage = ((completedCount / (inital_total + pending_count)) * 100);
                dispatch({ type: 'PERCENTAGE', payload: { percentage: get_percentage } })
                if (status) {
                    cb(res);
                    dispatch({ type: 'HIDE_LOADER' });
                }
                else fetchDataByThread(dispatch, pending_count, thread_id, inital_total, cb, error_cb);
            }, () => dispatch({ type: 'HIDE_LOADER' }), true);
        }, threadTime);
    }
    else {
        error_cb();
        dispatch({ type: 'HIDE_LOADER' })
    };
}

export const confirmBox = (message, actionFun = (status) => { }) => {
    swal({
        title: display('CONFIRM_TITLE'),
        text: message,
        icon: "warning",
        buttons: [display('NO'), display('YES')],
        dangerMode: true
    }).then(resp => {
        if (resp) {
            actionFun(resp);
        }
    }).catch(err => {

    });
}