
/*API CONSTANTS */
export const STATE_LIST = "org_config/entity_state_list";
export const FETCH_EMPLOYEES_ATTENDANCE = 'process/fetch_employees_attendance';
export const DELETE_EMPLOYEES_ATTENDANCE = 'process/recompute_employees_attendance';
export const LOP_DOWNLOAD_SAMPLE_URL = 'sample/lop';
export const LEAVE_ENCASH_DOWNLOAD_SAMPLE_URL = 'sample/leave_encashment';
export const DOWNLOAD_OT_SAMPLE = 'sample/overtime';
export const LOP_UPLOAD_FILE_URL = 'upload_file/lop';
export const SAVE_EMPLOYEES_ATTENDANCE = 'process/save_employees_attendance';
export const LOP_UPLOAD_URL = 'uploads/lop';
export const OT_UPLOAD_URL = 'uploads/overtime';
export const OT_UPLOAD_FILE_URL = 'upload_file/overtime';
export const OT_DOWNLOAD_SAMPLE_URL = 'sample/overtime';
export const LEAVE_ENCASH_UPLOAD_FILE_URL = 'upload_file/leave_encashment';
export const LEAVE_ENCASH_UPLOAD_URL = 'uploads/leave_encashment';
export const EDIT_SINGLE_ATTENDANCE = 'process/modify_employees_attendance';
export const COMPUTE_EMPLOYEES_PAYROLL = 'process/process_employees_payroll';
export const TDS_DOWNLOAD_SAMPLE_URL = 'sample/tds';
export const TDS_UPLOAD_FILE_URL = 'upload_file/tds';
export const TDS_UPLOAD_URL = 'uploads/tds';
export const BONUS_DOWNLOAD_SAMPLE_URL = 'sample/bonus';
export const BONUS_UPLOAD_FILE_URL = 'upload_file/bonus';
export const BONUS_UPLOAD_URL = 'uploads/bonus';
export const MISCELLANIOUS_DOWNLOAD_SAMPLE_URL = 'sample/miscellanious';
export const MISCELLANIOUS_UPLOAD_FILE_URL = 'upload_file/miscellanious';
export const MISCELLANIOUS_UPLOAD_URL = 'uploads/miscellanious';
export const ARREAR_DOWNLOAD_SAMPLE_URL = 'sample/arrear_days';
export const ARREAR_UPLOAD_FILE_URL = 'upload_file/arrear_days';
export const ARREAR_UPLOAD_URL = 'uploads/arrear_days';
export const DELETE_BULK_ARREAR = 'arrear/delete_bulk_arrear'
export const PROCESS_PAYROLL_SUMMARY = 'process/process_payroll_summary';
export const FETCH_BALANCE = 'payout/get_balance';
export const EMPLOYEE_PAYROLL_DETAILS = 'payout/employee_payroll_details';
export const UPLOADS_TDS = 'uploads/tds';
export const BULK_PUBLISH_PAYSLIPS = 'process/bulk_publish_payslips';
export const TOOGLE_PAYSLIPS = "payslip/toggle-payslip-visibility";
export const GET_REIMBUSEMENT_ADJUST_REQUEST = "employees/get_reimbursement_adjust_requests";
export const ACTION_REIMBUSEMENT_ADJUST_REQUEST = "employees/approve_decline_reimbursment_adjust";
export const GENRATEPAYSLIP = "process/publish_payslips"
export const MARK_AS_PAID = "process/marked_paid";
export const LEAVE_RULE_TYPELIST = 'auth/hrms/leave/leaverule_typelist';
export const CHECK_NPS_CONFIG = 'hrms/check_nps_config';
export const ADD_PRODUCT_NPS = 'hrms/add-product_nps';
export const PRODUCT_NPS_LOG = 'hrms/product_nps_add_log';
export const LEAVE_RULE_TYPELIST_BALANCE = 'hrms/user_leave_type_balance_summary';
export const DISBURSHMENT_AMOUNT = 'payout/compute_disburshment_amout';
export const OTP_REQUEST = 'payout/getTransferFundOtp';
export const VOICE_OTP_REQUEST = 'payout/getTransferFundVoiceOtp';
export const GET_MISCELLANIOUS_HEADS = 'modify/get_misc_heades';
export const MODIFY_EMPLOYEE_PAYROLL = 'process/modify_employees_payroll';
export const LOAN_EMI_DOWNLOAD_SAMPLE_URL = 'sample/loan_emi';
export const LOAN_EMI_UPLOAD_FILE_URL = 'upload_file/loan_emi';
export const LOAN_EMI_UPLOAD_URL = 'uploads/loan_emi/1';
export const ADVANCE_EMI_DOWNLOAD_SAMPLE_URL = 'sample/advance_emi';
export const ADVANCE_EMI_UPLOAD_FILE_URL = 'upload_file/advance_emi';
export const ADVANCE_EMI_UPLOAD_URL = 'uploads/loan_emi/2';
export const FILTERS_APPROVE_PROCESS_FLOW = "filters/get_filters/approverProcessFlow"

export const VALIDATE_OTP = "payout/validateOtpAndTransferFunds";
export const ADD_UPDATE_BONUS_RULES = "org_config/add_update_org_bonus_rule";
export const ADD_UPDATE_BONUS_HEADS = "org_config/add_update_org_bonus_head";
export const ORG_BONUS_HEADS = "org_config/org_bonus_heads";
export const ORG_BONUS_HEADS_LIST = "org_config/org_bonus_heads_list";
export const ORG_BONUS_RULES = "org_config/org_bonus_rules";
export const ORG_PF_CONFIG = "org_config/save_pf_config";
export const GET_ORG_PF_CONFIG = "org_config/org_pf_config";
export const ADD_PAYROLL_SETTING = "org_config/add_entity_payroll_setting";
export const ADD_OT_SETTING = "org_config/conf_ot_plains";
export const OT_CONFIG = "org_config/ot_config";
export const DELETE_OT_SETTING = "org_config/delete_ot_setting";
export const PROCESS_EMPLOYEES_ARREAR = "process/process_employees_arrear";
export const GET_ENTITY_PAYROLL = "org_config/get_entity_payroll_setting";
export const GET_ENTITY_SETTING = "org_config/get_entity_settings";
export const ENTITY_LIST = "org_config/org_entity_details";
export const UPDATE_ENTITY_INFO = "org_config/update_entity_info";
export const EMPLOYEE_LIST = "employees/employee_list";
export const GET_EMPLOYEE_LIST = "employees/get_employee_list"
export const GET_FNF_INFO = "process/get_fnf_info";
export const SAVE_FNF_INFO = "process/save_fnf_info";
export const GET_ADVANCE_SETTING = "org_config/advance_settings"
export const SAVE_ADVANCE_PAYROLL_SETTING = "org_config/save_advance_payroll_setting"
export const SAVE_ADVANCE_LEAVE_ENCASH_SETTING = "org_config/save_advance_leave_encash_setting"
export const ROLLBACK_FNF = "process/rollBackFnf";
export const GET_CTC_LOGS = "employees/action-logs";
export const EMPLOYEES_BANK_DETAILS = "employees/bank_details";
export const EMPLOYEE_REPORTS = "reports/";
export const SALARY_TEMPLATE_LIST = "org_config/get_salary_template_list";
export const GET_TEMPLATE_DETAILS = "org_config/get_template_details";
export const GET_MISCELLANIOUS_HEAD_LIST = "org_config/get_miscellanious_heads_list";
export const SAVE_MISCELLANIOUS_HEAD = "org_config/add_miscellanious_head";
export const UPDATE_MISCELLANIOUS_HEAD = "org_config/update_miscellanious_head";
export const LWF_LIST = "";
export const GET_LWF_SLABS = "org_config/get_lwf_slabs";
export const ADD_UPDATE_LWF_SLABS = "org_config/add_update_lwf_slabs";
export const LWF_DESIGNATION_DETAILS = "filters/get_filters/designation";
//export const UPDATE_LWF_SLABS = "org_config/update_lwf_slabs";
export const UPDATE_LWF_SLABS = "org_config/update_lwf_slabs";
export const ONBOARDING_ENTITY_DETAILS = "org_config/get_default_entity_details";
export const GET_EMPLOYEE_FNF = "process/get_employee_fnf";
export const ONBOARD_STATUS = "org_config/onboard_status";
export const APPROVE_DECLINE_REIMBURSMENT = "finance/approve_decline_reimbursment";
export const BUDGET_FORCE_CAST = "dashboard/budget_fore_cast";
export const PAYROLL_PENDING_LIST = "dashboard/pending_list"
export const CTC_REQUEST_LIST = "dashboard/ctc_approvel_request_count"
export const PAYROLL_PENDING_REQUEST = "dashboard/payroll_request";
export const PAYROLL_PENDING_BENIFITS = "dashboard/benfits_and_taxation_pending_requests";
export const DASHBOARD_LOAN_DETAILS = "dashboard/loans_details";
export const DASHBOARD_COST_TO_COMPANY = "dashboard/cost_to_company_analyis";
export const TAX_CONGIGURATION = "org_config/tax_declaration_head_hide";
export const FREEZE_UNFREEZE_DECLARATION = "finance/freeze_unfreeze_declaration";
export const PROCESS_REQUEST_STATUS = "approvel_process/update_payroll_process_request_status";
export const CTC_REQUEST_STATUS = "ctc_approvel_process/update_ctc_approvel_request_status"
export const FORMTU_DOWNLOAD_FORMS = "forms/download_forms";
export const FORMTU_HISTORY = "forms/forms_history";
export const FREEZE_UNFREEZE_REIMBURSEMENT = "finance/freeze_unfreeze_reimbursement";
export const GET_REIMBURSMENT_EMPLOYEES = "finance/get_reimbursement_employees";
export const FREEZE_UNFREEZE_RESTRUCTURE = "finance/freeze_unfreeze_restructure";
export const GET_RESTRUCTURE_EMPLOYEES = "finance/get_restructure_employees";
export const PROCESS_EMPLOYEE_FNF = "process/process_employee_fnf";
export const SAVE_EMPLOYEES_ARREAR = "process/save_employees_arrear";
export const GET_SLUGS_HEADERS = "process/get_slugs_headers";
export const UPDATE_SLUGS_HEADERS = "process/update_headers";
export const FREEZE_PAYROLL = "process/freeze_payroll";
export const EMPLOYEE_FREEZE_PAYROLL = "process/freeze_and_unfreeze_payroll"
export const BANK_SHEET_DOWNLOAD = "reports/bank_sheet_download";
export const EXPENSE_SHEET_DOWNLOAD = 'expense/expense_bank_sheet'
export const ASSUMEDAYS_CONFIGURATION = 'assume_days/get_assumedays_configration_list';
export const SAVE_ASSUMEDAYS = 'assume_days/save_assumedays_configration';
export const DELETE_ASSUMEDAYS = 'assume_days/delete_assumedays_configration';
export const ACTION_HISTORY_REPORT = "reports/download/employee_log_report";
export const GET_ACTION_HISTORY_REPORT_FILTER = "filters/get_filters/employee_log_report";
export const UNSETTLED_ARREAR_PAYDAYS = 'process/calculate_unsettled_arrear_paydays';
export const ADD_ARREAR_DAYS = "process/add_arrear_days"

export const ACTIVE_COUNTRY = "org_config/set_active_country";
export const EMAIL_REMINDER = "email/tax_proof_reminder"
export const GET_TDS_24Q_CONFIGURATION = "tds_24q/get_tds_24q_configuration";
export const ADJUST_EMI_TENURE = "loan_advance/adjuctEmiAndTenure";
export const TOGGLE_PERQUISITE = "perquisites/enable_disable_perquisite";
export const EDIT_UPDATE_PERQUISITES_FORM = "perquisites/add_update_emp_perquisites_config"
export const CHECK_THREAD_STATUS = 'checkThreadStatus'

export const DELETE_BULK_UPLOAD = 'bulk_reversal/delete_bulk_upload_details';
export const DECLARATION_HEAD_LIST = "tax_declaration/declaration_head_list";
export const SAVE_DECLARATION = "tax_declaration/save_investment_declaration"
export const FILTER_TAX_DECLARATION = "filters/get_filters/tax_declaration_employee";
export const GET_TDS_DECLARATION = "finance/get_tds_declaration";
export const APPROVE_DECLINE_DECLARATION = "finance/approve_decline_declaration";
export const ZIP_TAX_DECLARATION_PROOF = "tax_declaration/zip_tax_declaration_proof"
export const GET_PERQUISITES_CONFIG = "perquisites/get_emp_perquisites_config"
export const GET_BULK_UPLOAD = 'bulk_reversal/get_bulk_upload_details';
export const BULK_UPLOAD_LIST = 'bulk_reversal/bulk_drop_down_list';
export const EXPENSE_DETAILS = "expense/expense_details";
export const PAYROLL_PROCESS_REQUEST = "approvel_process/get_payroll_process_request";
export const UPLAOD_FORM_16_A = "forms/forms_sixteen_a"
export const CTC_PROCESS_REQUEST = "ctc_approvel_process/get_ctc_approvel_process_request";
export const GET_STRUCTURE_LIST = "process/ssl_structure_list";
export const GET_CTC_TO_GROSS = "process/ssl_ctc_to_gross";
export const BULK_SAMPLE_DOWNLOAD = "process/ssl_bulk_sample_download";
export const BULK_FILE_UPLOAD = "process/ssl_bulk_file_upload";
export const CUSTOM_FNF_HEADS = "reports/custom_fnf_heads";
export const CREATE_CUSTOM_REPORT = "reports/create_custom_fnf_report_style"
export const GET_RESTRUCTURE_CONFIG = 'org_config/get_restructure_config';
export const SAVE_RESTRUCTURE_CONFIG = 'org_config/save_restructure_config';
export const STEP_WITH_FILTER = "process/stepWithFilter";
export const GET_PAYROLL_SUMMARY_FILTER = "process/get_payroll_summary_via_filter";
export const GET_CUSTOMMER_HEAD_NAME = "newreport/customerHeadName";
export const SAVE_CUSTOMMER_HEAD_NAME = "newreport/createMyCustomReportStyle";
export const SAVE_SIF_REPORT = "reports/create_sif_report";
export const GET_CUSTOM_REPORT_LIST = "reports/get_custom_reports_list";
export const DELETE_CUSTOM_REPORT_LIST = "reports/delete_custom_report";
export const GET_EDIT_CUSTOM_HEAD = "newreport/addHeadsIncustom";
export const GET_INSTANT_ADVANCE_FILTER = "filters/get_filters/instant_advance";
export const GET_INSTANT_ADVANCE_LIST = "benefits/inhouse/instant_advance_requests";
export const APPROVE_INSTANT_ADVANCE = "benefits/inhouse/approve-instant-advance";
export const GET_INSTANT_ADVANCE_TRANSFER_OTP = "benefits/getTransferFundOtp";
export const VALIDATE_INSTANT_ADVANCE_TRANSFER_OTP = "benefits/validateOtpAndTransferAdvance";
export const GET_INSTANT_ADVANCE_WORKFLOW = "benefits/inhouse/get-approver-workflow";
export const UPDATE_INSTANT_ADVANCE_WORKFLOW = "benefits/inhouse/update-approver-workflow";
export const APPLY_ADVANCE_ON_BEHALF = "benefits/inhouse/instant_onbehalf_advance_requests";


export const HRA_DECLARATION_ZIP = "finance/download_monthly_hra_declaration_zip"
export const HRA_DECLARATION_MONTHLY_LIST = "finance/monthly_hra_declaration_list"
export const GET_PROCESS_ARREAR_DETAILS = "whitelist_api/get_process_arrear_details";

export const HOUSE_LOAN_DECLARATION_LIST = "finance/house_loan_declaration_list";
export const HOUSE_LOAN_DECLARATION_ZIP = "finance/download_house_loan_declaration_zip"

export const INSURANCE_ACTIONS = "finance/modify_insurance"
export const GET_LOAN_FILTERS = "filters/get_filters/loans";
export const ADVANCE_LOAN_REQUEST = "loan_advance/loansRequest";
export const ADVANCE_LOAN_PROOF = "loan_advance/download_proof";
export const ADVANCE_LOAN_REJECT = "loan_advance/rejectLoan";
export const ADVANCE_LOAN_APPROVE = "loan_advance/approveLoan";
export const ADVANCE_LOAN_COMPUTELOANEMI = "loan_advance/computeLoanEMI"
export const FILTER_VENDOR = "filters/get_filters/vendor";
export const GET_VENDOR_PAYOUT_REQUEST_LIST = "vendor/get_vendor_payout_request_list";
export const GET_VENDOR_BANK_SHEET = "vendor/get_vendor_bank_sheet";
export const MARK_AS_PAID_VENDOR = "vendor/mark_as_paid_vendor"

/** payout apis start */
export const SET_VENDOR = "payout/set_vendor";
export const GET_VENDOR = "payout/vendor";
export const GET_ENTITY_INFO = "payout/entity_info";
export const GET_BALANCE_TOKEN = "payout/link/balanceToken";
export const GET_REGISTRATION_TOKEN = "payout/link/registrationToken";
export const UPDATE_PAYOUT_REG_INFO = "payout/link/update_payout_reg_info";
export const SAVE_ACCOUNT_LINK_STATUS = "payout/link/save_account_link_status";
export const REGISTER_PAYOUT = "payout/register";
export const CREATE_WITHDRAWAL_BENEFICIARY = "payout/createWithdrawalBeneficiary";
export const GET_WITHDRAWAL_INFO = "payout/getWithdrawalAccountInfo";
export const VALIDATE_WITHDRAW_OTP = "payout/validateOtpAndwithdrawFund";
export const GET_CUSTOMER_TOKEN = "payout/link/customerToken";
export const DOWNLOAD_PAYOUT_STATEMENT = "payout/downloadStatments";
export const PAYOUT_DOWNLOAD_STATEMENT = "payout/pullStatments";
export const REINITIATE_PAYOUT_TRANSACTION = "payout/reInitiateTransaction";
export const GET_ORG_FILTERS = "org_config/get_filters";
export const GET_EMPLOYEE_PAYROLL_DETAILS = "payout/link/employee_payroll_details";
export const INIT_TRANSACTION = "payout/link/initTransactions";
export const PAYOUT_PULL_TRANSACTION_STATUS = "payout/link/pullTransactionStatus";
export const PAYOUT_PULL_TRANSACTION = "payout/pullTransactionStatus";
export const GET_BENEFICIARY_LIST = "payout/beneficiary_list";
export const DELETE_BENEFICIARY = "payout/deleteBeneficiary";
export const GET_PAYOUT_EMPLOYEE_LIST = "payout/employee_list";
export const CREATE_PAYOUT_BENEFICIARY = "payout/createBeneficiary";
export const GET_LINK_BENEFICIARY_LIST = "payout/link/beneficiary_list";
export const DELETE_LINK_BENEFICIARY = "payout/link/deleteBeneficiary";
export const DOWNLOAN_PAYMENTS = "payout/downloadPayments";
export const DOWNLOAN_LEDGER = "payout/downloadLedger";
export const GET_PULL_LEDGER = "payout/pullLedger";
export const GET_PULL_PAYMENTS = "payout/pullPayments";
export const VALIDATE_OTP_TRANSFER_FUNDS = "payout/validateOtpAndTransferExpenseFunds";
export const GET_EMPLOYEE_EXPENSE_DETAILS = "payout/employees_expense_details";
export const CUMPUTE_EXPENSE_DISBURSHMENT = "payout/computeExpenseDisburshmentAmout";
export const GET_PAYOUT_FNF_EMPLOYEE_DETAILS = "payout/fnf_employee_payroll_details";
export const GET_VALIDATE_OTP_TRANSFER_FNF_FUNDS = "payout/validateOtpAndTransferFnfFunds";

/** payout apis end */

export const GET_COMPLIANCES_LIST = "compliances/list";
export const GET_COMPLIANCES_DETAILS = "compliances/slabs"
export const PREVIEW_ENTITY_TEMPLATE = "org_config/preview_entity_template";
export const ENTITY_SALARY_SLIP_LIST = "org_config/entities_salary_slip_list";
export const PREVIEW_TEMPLATE = "org_config/preview_template";
export const SAVE_TEMPLATE_DETAILS = "org_config/save_template_details";
export const GET_RESETTLED_EMPLOYEE = "process/get_resettled_employees";
export const SAVE_RESETTLED_EMPLOYEE = "process/save_resettled_employees";
export const DOWNLOAD_FNF_SHEET = "process/download_fnf_sheet";
export const RECOMPUTE_FNF = "process/recompute_fnf";
export const MODIFY_FNF_MANUALLY = "process/modify_fnf_details_manually"
export const CUMPUTE_EMPLOYEE_GRATUITY = "employees/calculate_gratuity";
export const LOAN_ADVANCE_UPFRONT_PROCESS = "loan_advance/upfrontLoanAndAdvanceProcess"
export const LOAN_ADVANCE_OUTSTANDING_EMI_AMOUNT = "loan_advance/outStandingEmiAMount"
export const GET_MINIMUM_WAGES_SLABS = "org_config/get_minimum_wages_slabs";
export const GET_MIN_WAGE_FORM_DATA = "filters/get_filters/min_wage_create";
export const CREATE_ORG_MIN_WAGES = "org_config/add_central_min_wages";
export const UPDATE_ORG_MIN_WAGES = "org_config/update_central_min_wages";
export const CHECK_EMP_FNF_DETAILS = "employees/check_fnf_details";
export const INSURANCE_LIST = "finance/get_insurance_list"
export const APPROVE_DECLINE_OTHER_EARNINGS = "finance/approve_decline_other_earnings"

export const GET_GLOBAL_SETTING = "org_config/global_setting";
export const ADD_UPDATE_GLOBAL_SETTING = "org_config/add_update_global_settings";
export const REORDER_SALARY_STRUCTURE_HEAD = "org_config/reorder_org_salary_structure_heads";

export const PAYSLIP_FILTER = "filters/get_filters/payslip";
export const PAYSLIP_EMP_LIST = 'payslip/payslip_employee_list';
export const PAYSLIP_DOWNLOAD_SLIP = "payslip/download_salary_slip";
export const PAYSLIP_DOWNLOAD_ZIP = "payslip/download_salary_slip_zip";
export const PAYSLIP_SLIP_EMAIL = "payslip/salary_slip_email";
export const PAYSLIP_SLIP_NOTIFICATION = "payslip/salary_slip_notification"
export const MIN_WAGE_VIOLATION = "dashboard/min_wage_violation"

export const EMPLOYEE_REIM_FREEZE_STATUS = 'finance/get_employee_reimb_freeze_status';
export const EMPLOYEE_REIM_HEADS = 'finance/get_employee_reimburement_heads';
export const REIM_REQUEST = 'finance/reimbursement_request';

//checklist fnf

export const FNF_EMPLOYEE_DETAIL = "process/fnf/employee-details";
export const FNF_GET_DRAFT = "process/fnf/get-draft";
export const FNF_SAVE_DRAFT = "process/fnf/save-draft";
export const GET_FNF_PAYROLL = "process/fnf/get-fnf-payroll"
export const GET_LEAVE_ENCASHMENT = "process/fnf/get-leave-encashment"
export const UPDATE_TAX_REGIME = "imports/uploads/101/tax_regime"
export const FORM_16_STATUS_LIST = "employees/form_16_status_list";
export const FORM_16_OPTIONS = "forms/get-form16-options";
export const FORM_PROCESS_16 = "forms/process-form16";
export const DOWNLOAD_FORM_16 = "forms/download_form_sixteen_a";
export const FORM_16_MERGE_OPTIONS = "forms/form16-merge-options";
export const UPLOAD_PFX_CERTIFICATE = "forms/upload_pfx_certificate"
export const VERIFY_SIGN_KEY = "forms/verify_sign_key";
export const GET_PFX_CERTIFICATE = "forms/get_pfx_certificate";
export const SAVE_TDS_REFRENCE_MONTH = "process/save_tds_reference_month"

//common modal constant
export const BREAKDOWN_ARREAR_MODAL = 'Arrer BreakDown';
export const BULK_RECOMPUTE_ALERT = 'bulk_recompute_alert';
export const PAYROLL_RECOMPUTE_ALERT = "payroll_recompute_alert";
export const DISBURSHMENTSUMMARY = 'disburshment summary';
export const HEAD_CONFIG = "head_config";
export const RULES_CONFIG = "rules_config";
export const COMMON_ALERT = "common_alert";

//common drawer contsnat
export const EDIT_EMPLOYEE_DRAWER = 'EditEmployee';
export const EMPLOYEE_PAYROLL_DRAWER = 'EmployeePayroll';
export const INT_EMPLOYEE_PAYROLL_DRAWER = "IntEmployeePayroll";
export const EDIT_PAYROLL_DRAWER = 'EditPayroll';
export const SALARY_STRUCTURE_HEADS = 'org_config/org_salary_structure_heads_config';
export const SALARY_STRUCTURE_HEAD_STATUS = 'org_config/org_salary_structure_heads_status';
export const SALARY_STRUCTURE_HEAD_RENAME = 'org_config/org_salary_structure_rename_head';
export const SALARY_STRUCTURE_NEW_HEAD = 'org_config/org_salary_structure_new_head';


//global-routes
export const MainRoutePath = "/beta-payroll";
export const threadTime = 5000;

//Form 24Q
export const filters_challan_list = 'filters/get_filters/challan_list';
export const filters_challan_acknowledge = 'filters/get_filters/challan_acknowledge';
export const filters_challan_acknowledge_input = 'filters/get_filters/challan_acknowledge_input';
export const get_tds_24q_acknowledge = 'tds_24q/get_tds_24q_acknowledge';
export const get_challan_list = 'tds_24q/get_challan_list';
export const tds_24q_freeze_payroll = 'tds_24q/tds_24q_freeze_payroll';
export const filters_tally_config = 'filters/get_filters/tally_config';
export const tally_sync_status = `integration/tally/tally_sync_status`;
export const filters_unmapping_challan = "filters/get_filters/unmapped_challan_list";
export const UNMAPPED_CHALLAN_LIST = "tds_24q/delete_challan_list";
export const DELETE_CHALLAN = "tds_24q/unmapped_challan";
export const UPLOAD_CSI_FILE = "tds_24q/upload_csi_file"

//email reports

export const EMAIL_REPORT_LIST = "reports/get-email-report-list";
export const SEND_MAIL_WITH_REPORT = "employees/send-multiple-reports"

// tips apis
export const TIPS_ATTENDANCE_LIST = "filters/get_filters/hrms_attendance_list";
export const TIPS_EMPLOYEE_ATTENDANCE = "employees/hrms-attendance";

export const TIPS_PAYROLL_ATTENDANCE = "employees/payroll-attendance";
export const TIPS_AMOUNT_DISTRIBUTION = "tips/amount-distribution";
export const TIPS_DESIGNATION_TIP_CONF = "tips/designation-tip-conf";
export const TIPS_UPDATE_TIP_CONFIG = "tips/update-designation-tip-conf"
export const TIPS_DESIGNATION_LIST = "tips/designation-list";
export const TIPS_HEAD_EXISTES = "tips/tip-head-exists";
export const TIPS_SAVE_TIP_DATA = "tips/save-tip-data";
export const TIPS_ATTENDANCE_UPDATE = "tips/update-tip-attd";
export const TIPS_ATTENDANCE_CHECK = "tips/check-tip-attd";

// email library
export const GET_EMAIL_LIBRARY_LIST = "email/get_email_library_list";
export const SAVE_EMAIL_TEMPLATE = "email/save_email_template";


//checklist apis 

export const RE_COMPUTE_PAYROLL ="process/process_employees_payroll_monthwise";
export const EDIT_FNF_PAYROLL = "process/fnf/edit-fnf-payroll";
export const RECOMPUTE_ATTENDANCE = "process/recompute_att_and_save";
export const FNF_COMPONENTS = "process/fnf/get-fnf-components";
export const GRATUITY_API = "process/fnf/get-gratuity";
export const SAVE_FNF_COMPONENTS = "process/fnf/save-fnf-components"
export const SUMMARY_DETAILS = "process/fnf/get-fnf-summary"
export const SELECT_TAX_REGIME = "imports/uploads/101/tax_regime"
export const GENERATE_FNF = "process/fnf/generate-fnf-settlement"
//Compilances --  PTslab , LWF SLAB , MINIMUM wages

 const INDIAN_COUNTRY_ID = 101;
 
export const COMPILANCES_REPORT_DOWNLOAD = `reports/download/${INDIAN_COUNTRY_ID}/compliance_report`;



//BULK PAYSLIP 

export const BULK_GENERATE_PAYSLIP = "finance/request-bulk-annual-slips";
export const BULK_GENERATE_PAYSLIP_TABULAR_DATA = "finance/status-bulk-annual-slips"
export const GET_FNF_PROJECTION = "process/fnf/fnf-projection"
export const TDS_VALUE = "process/fnf/modify-tds";
export const APPROVER_DETAIL_LIST = "org_config/tax_declaration_notification_list";
export const NOTICE_PERIOD_PENALTY_CHECKLIST = "process/fnf/set-waive-off-notice-period";
export const NOTICE_PERIOD_PENATLITY_PDF_DATA_SEND = "process/fnf/fnf-projection-pdf";
export const DELETE_DRAFT_DATA = "process/fnf/fnf-clear-progress";

export const EMPLOYEE_MAPPED_TO_STRUCTURE = "org_config/org_salary_structure_mapped_list";
export const EMPLOYEE_FILTERS = "filters/get_filters/employees";
export const STRUCTURE_MAPPED_EMPLOYEE_PREVIEW = "org_config/org_salary_structure_remap_employees";
export const ORG_SALARY_STRUCTURE_SAVE_URL = "org_config/org_salary_structure_save_salary";


// PATHS / Configurations > Compliances > ESIC
export const CONFIG_COMPLIANCE_ESIC_SETTING = "org_config/org_esi_config_details";
export const CONFIG_COMPLIANCE_ESIC_SETTING_DEFAULT = "org_config/esi_setting_default";
export const CONFIG_COMPLIANCE_ESIC_SETTING_CONFIGURE = "org_config/esi_setting_configure";
