import React, { useMemo, useState } from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import CreatableSelect from 'react-select/creatable';
import { filterOptions, hashSearch, TARGET_KEY } from './constant';

const CustomSelect = ({
    options,
    required = false,
    name,
    inputHandler,
    placeholder = 'select...',
    values,
    valueKey = 'id',
    labelKey = 'name',
    isMulti = false,
    isAsync = false,
    isCreatable = false,
    isDisabled = false,
    isLoading = false,
    styles = {},
    components = {},
    handleSearch = false,
    additionalInfo = {},
    ...props
}) => {


    const [selectedDefaultOptions, setSelectedOptions] = useState([]);
    const allTarget = [{ [valueKey]: TARGET_KEY, [labelKey]: `All ${additionalInfo.label}` }];
    const formattedOptions = useMemo(() => {
        let data = [];
        data = options.map(item => {
            if (typeof item === 'string' || typeof item === 'number') {
                return { [valueKey]: item, [labelKey]: item };
            }

            return item;
        });

        return data;

    }, [options]);

    const getOptions = useMemo(() => isMulti ? [...allTarget, ...formattedOptions] : formattedOptions, [formattedOptions]);

    const formattedValue = useMemo(() => {
        if (!isMulti && (values || values == 0)) {
            return formattedOptions.find(option => `${option[valueKey]}` === `${values}`);
        }
        else if (isMulti && values && values.length > 0) {
            if (Array.isArray(values)) {
                return values.map(val => formattedOptions.find(option => `${option[valueKey]}` === `${val}`));
            }
        }

        return [];

    }, [isMulti, values]);

    function loadOptions(inptutValue, callback) {

        handleSearch(inptutValue, callback);

    }

    // Handle change event
    const handleChange = (selectedOptions, actionsOptions) => {

        const { action, option: action_type } = actionsOptions;

        const get_action_value = action_type?.[valueKey];

        let selectedValues = {};
        if (isMulti) {
            if (hashSearch(selectedOptions, TARGET_KEY, valueKey) && action === 'select-option') {
                selectedValues = filterOptions(
                    formattedOptions,
                    option => option[valueKey] !== TARGET_KEY,
                    valueKey
                );

                // formattedOptions.reduce(function (filtered, option) {
                //     if (option[valueKey] !== TARGET_KEY) {
                //         filtered.pass_filter.push(option[valueKey]);
                //         filtered.store_value.push(option);
                //     }
                //     return filtered;
                // }, { pass_filter: [], store_value: [] });
                // selectedValues = formattedOptions?.map(option => {
                //     return option[valueKey];
                // });

                // console.log('tyuiouified', selectedValues);
            }
            else if (get_action_value == TARGET_KEY && action === 'deselect-option') {
                selectedValues = { pass_filter: [], store_value: [] };
            }
            else {
                selectedValues = filterOptions(
                    selectedOptions,
                    option => option[valueKey] !== TARGET_KEY,
                    valueKey
                );



                // selectedOptions.reduce(function (filtered, option) {
                //     if (option[valueKey] !== TARGET_KEY) {
                //         filtered.pass_filter.push(option[valueKey]);
                //         filtered.store_value.push(option);
                //     }
                //     return filtered;
                // }, { pass_filter: [], store_value: [] });
                // selectedOptions?.map(option => {
                //     if (option?.__isNew__) options.push(option)
                //     return option[valueKey];
                // });
            }
            setSelectedOptions([...selectedValues.store_value]);
            inputHandler({ target: { name, value: selectedValues.pass_filter } });
        } else {
            inputHandler({ target: { name, value: selectedOptions ? selectedOptions[valueKey] : '' } });
        }
    };

    const isSelectAllSelected = useMemo(() => {
        return selectedDefaultOptions.length === formattedOptions.length;
    }, [selectedDefaultOptions, formattedOptions]);


    const optionsWithSelectAll = isSelectAllSelected ? [...allTarget, ...(formattedValue ?? [])] : formattedValue;

    let SelectComponent = Select;
    if (isAsync) {
        SelectComponent = AsyncSelect;
    } else if (isCreatable) {
        SelectComponent = CreatableSelect;
    }

    const selectProps = {
        name,
        required,
        options: getOptions,
        getOptionLabel: (option) => option[labelKey],
        getOptionValue: (option) => option[valueKey],
        isMulti,
        onChange: handleChange,
        placeholder: `Select ${additionalInfo.label}`,
        defaultValue: values,
        isDisabled,
        isLoading,
        styles,
        components,
        ...props,
    };

    // Special handling for AsyncSelect
    if (isAsync && typeof loadOptions === 'function' && typeof handleSearch === 'function') {
        return <SelectComponent
            loadOptions={loadOptions}
            defaultOptions={formattedOptions}
            {...selectProps} />;
    }

    return <SelectComponent menuPosition="fixed" maxMenuHeight={200} {...selectProps} styles={{
        control: (base) => ({ ...base, flexWrap: 'nowrap' }),
        menu: (base) => ({
            ...base, zIndex: 100,
        }),
        input: (provided) => ({
            ...provided,
            minWidth: '5%'
        }),
        placeholder: (base, state) => ({
            ...base,
            ":after": state.selectProps.required && {
                content: "'*'",
                marginLeft: 5,
                color: "red"

            }
        }),
        menuPortal: (base) => ({
            ...base, zIndex: 999
        }),
        option: (provided, state) => ({
            ...provided,
            cursor: 'pointer',
            display: "flex",
            fontSize: 14,
            backgroundColor: state.isSelected && state.isMulti ? 'transparent' : provided.backgroundColor,
            color: state.isSelected && state.isMulti ? 'black' : provided.color,
            ':hover': {
                backgroundColor: '#f7f7f7',
                color: 'black',
            }
        }),
        valueContainer: (base) => ({
            ...base,
            height: 39,
            zIndex: 100,
            // display: "inline-block",
            flexWrap: 'nowrap',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            // textOverflow: 'ellipsis',
            // alignItems: "center"
        }),
    }} value={isMulti ? optionsWithSelectAll : formattedValue} />;
};

export default CustomSelect;
