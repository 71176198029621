import { styled } from '@mui/styles';
import { alpha } from '@mui/system';

export const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: 3,
    width: 16,
    height: 16,
    [theme.breakpoints.down("md")]: {
        width: 15,
        height: 15,
    },
    boxShadow: `${alpha(theme.palette.primary.main, 0.5)} 0px 0px 0px 1px`,
    backgroundColor: '#f5f8fa',
    // backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
    },
    'input:hover ~ &': {
        backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
        boxShadow: 'rgba(0, 0, 0, 0.26) 0px 0px 2px 1px',
        backgroundColor: '#6d6c6c33'
    },
    'input:disabled:checked ~ &': {
        //boxShadow: 'none',
        background: 'rgba(0, 0, 0, 0.26)',
    },
}));

export const BpCheckedIcon = styled(BpIcon)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    boxShadow: `${theme.palette.background.secondary} 0px 0px 2px 1px`,
    '&::before': {
        display: 'block',
        width: 16,
        height: 16,
        [theme.breakpoints.down("md")]: {
            width: 15,
            height: 15,
        },
        backgroundImage: "url(\"data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='SVGRepo_bgCarrier' stroke-width='0'%3E%3C/g%3E%3Cg id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'%3E%3C/g%3E%3Cg id='SVGRepo_iconCarrier'%3E%3Cg clip-path='url(%23ffffffclip0_429_11249)'%3E%3Cpath d='M20 7.00018L10 17.0002L5 12.0002' stroke='%23ffffff' stroke-width='2.5' stroke-linecap='round' stroke-linejoin='round'%3E%3C/path%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_429_11249'%3E%3Crect width='24' height='24' fill='white'%3E%3C/rect%3E%3C/clipPath%3E%3C/defs%3E%3C/g%3E%3C/svg%3E\")",
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: theme.palette.primary.main,
    },
    'input:disabled ~ &': {
        boxShadow: 'rgba(0, 0, 0, 0.26) 0px 0px 2px 1px',
        backgroundColor: '#6d6c6c33'
    },
}));

export const BpIntermediateIcon = styled(BpIcon)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    boxShadow: `${theme.palette.background.secondary} 0px 0px 2px 2px`,
    '&::before': {
        display: 'block',
        width: 17,
        height: 17,
        [theme.breakpoints.down("md")]: {
            width: 15,
            height: 15,
        },
        backgroundImage: "url(\"data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='SVGRepo_bgCarrier' stroke-width='0'%3E%3C/g%3E%3Cg id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'%3E%3C/g%3E%3Cg id='SVGRepo_iconCarrier'%3E%3Cpath d='M6 12L18 12' stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3C/path%3E%3C/g%3E%3C/svg%3E\")",
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: theme.palette.primary.main,
    },
}));